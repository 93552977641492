import * as Ord from 'fp-ts/Ord'
import * as A from 'fp-ts/ReadonlyArray'
import { pipe } from 'fp-ts/function'
import { ordOrderNumber, sortImageFileEntityByOrderByOrderNumber } from '@api/endpoints/files'
import { BookingOption, BookingOptionValue } from './types'

const ordBookingOptionByOrderNumber = pipe(
  ordOrderNumber,
  Ord.contramap((o: BookingOption) => o.orderNumber)
)

const ordBookingOptionValueByOrderNumber = pipe(
  ordOrderNumber,
  Ord.contramap((o: BookingOptionValue) => o.orderNumber)
)

export const filterEmptyAndSortBookingOptions = (options: ReadonlyArray<BookingOption>): ReadonlyArray<BookingOption> =>
  pipe(
    options,
    A.filter((o) => o.values.length > 0),
    A.map((o) => ({
      ...o,
      values: pipe(
        o.values,
        A.map((v) => ({
          ...v,
          images: pipe(v.images, sortImageFileEntityByOrderByOrderNumber).concat()
        })),
        A.sort(ordBookingOptionValueByOrderNumber)
      ).concat()
    })),
    A.sort(ordBookingOptionByOrderNumber)
  )
