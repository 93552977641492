import * as z from 'zod'
import { EmailAddress, Language } from '../types'

export const EmailVerificationSessionId = z.string().uuid()
export type EmailVerificationSessionId = z.infer<typeof EmailVerificationSessionId>

export const VerificationCode = z.string().min(6).max(6)
export type VerificationCode = z.infer<typeof VerificationCode>

export const StartEmailVerificationRequest = z.object({
  emailAddress: EmailAddress,
  language: Language
})
export type StartEmailVerificationRequest = z.infer<typeof StartEmailVerificationRequest>

export const ConfirmEmailVerificationRequest = z.object({
  verificationCode: VerificationCode
})
export type ConfirmEmailVerificationRequest = z.infer<typeof ConfirmEmailVerificationRequest>
