/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QueryKey } from '@tanstack/react-query'
import { useApiMutation, useApiQuery } from '@digital-magic/react-common/lib/api/hooks'
import { OptionalType } from '@digital-magic/ts-common-utils/lib/type'
import { apiBaseUrl } from '@constants/configuration'
import { ApiMutationOpts, ApiQueryOpts } from '@api/types'
import { callOnly, receiveOnly, sendAndReceive, sendOnly } from '@api/utils'
import { queryKeys as houseQueryKeys } from '@api/endpoints/buildings/houses'
import { queryKeys as layoutTypeQueryKeys } from '@api/endpoints/buildings/layoutTypes'
import { queryKeys as optionQueryKeys } from '@api/endpoints/buildings/options'
import { IdObject } from '../types'
import {
  Booking,
  BookingId,
  BookingNumber,
  BookingState,
  BookingsList,
  ConfirmBookingRequest,
  CreateBookingRequest,
  SubmitBookingRequest,
  UpdateBookingRequest
} from './types'

const bookingsUrl = `${apiBaseUrl}/bookings`
export const bookingUrl = (bookingId: BookingId): string => `${bookingsUrl}/${bookingId}`
const bookingSubmissionUrl = (bookingId: BookingId): string => `${bookingUrl(bookingId)}/submission`
const bookingConfirmUrl = (bookingId: BookingId): string => `${bookingUrl(bookingId)}/confirmation`
const bookingRestorationUrl = (bookingId: BookingId): string => `${bookingUrl(bookingId)}/restoration`

const queryKeys = {
  getBookingsAll: ['getBookingsAll'],
  getBookings: (bookingNumber: BookingNumber | undefined, bookingState: BookingState | undefined): QueryKey => [
    ...queryKeys.getBookingsAll,
    bookingNumber,
    bookingState
  ],
  getBooking: (bookingId: BookingId) => ['getBooking', bookingId]
}

const affectedKeysByCreateOrDelete = [
  queryKeys.getBookingsAll,
  optionQueryKeys.getOptionsAll,
  optionQueryKeys.getOptionAny,
  optionQueryKeys.getOptionValuesAll,
  optionQueryKeys.getOptionValueAny,
  layoutTypeQueryKeys.getLayoutTypeAny,
  houseQueryKeys.getHouseAny,
  houseQueryKeys.getBuildingAny
]

export const useGetBookings = (
  bookingNumber: OptionalType<BookingNumber>,
  bookingState: OptionalType<BookingState>,
  opts?: ApiQueryOpts<BookingsList>
) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: bookingsUrl,
        params: { bookingNumber, bookingState },
        responseSchema: BookingsList
      }),
    // TODO: Is it a problem?
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey:
      bookingNumber || bookingState ? queryKeys.getBookings(bookingNumber, bookingState) : queryKeys.getBookingsAll,
    ...opts
  })

export const useCreateBooking = (opts?: ApiMutationOpts<IdObject, CreateBookingRequest>) =>
  useApiMutation({
    mutationFn: (data) =>
      sendAndReceive({
        method: 'post',
        url: bookingsUrl,
        requestSchema: CreateBookingRequest,
        responseSchema: IdObject,
        data
      }),
    invalidateQueries: [...affectedKeysByCreateOrDelete],
    ...opts
  })

type WithBookingId = {
  id: BookingId
}

type UpdateBookingRequestWithParams = UpdateBookingRequest & WithBookingId

export const useUpdateBooking = (opts?: ApiMutationOpts<void, UpdateBookingRequestWithParams>) =>
  useApiMutation({
    mutationFn: ({ id, ...data }) =>
      sendOnly({
        method: 'put',
        url: bookingUrl(id),
        requestSchema: UpdateBookingRequest,
        data
      }),
    invalidateQueries: [...affectedKeysByCreateOrDelete],
    ...opts
  })

export const useGetBooking = (bookingId: BookingId, opts?: ApiQueryOpts<Booking>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: bookingUrl(bookingId),
        responseSchema: Booking
      }),
    queryKey: queryKeys.getBooking(bookingId),
    ...opts
  })

export const useDeleteBooking = (opts?: ApiMutationOpts<void, BookingId>) =>
  useApiMutation({
    mutationFn: (bookingId) =>
      callOnly({
        method: 'delete',
        url: bookingUrl(bookingId)
      }),
    invalidateQueries: [...affectedKeysByCreateOrDelete],
    ...opts
  })

type SubmitBookingRequestWithParams = SubmitBookingRequest & WithBookingId

export const useSubmitBooking = (opts?: ApiMutationOpts<void, SubmitBookingRequestWithParams>) =>
  useApiMutation({
    mutationFn: ({ id, ...data }) =>
      sendOnly({
        method: 'post',
        url: bookingSubmissionUrl(id),
        requestSchema: SubmitBookingRequest,
        data
      }),
    invalidateQueries: [...affectedKeysByCreateOrDelete],
    ...opts
  })

type ConfirmBookingRequestWithParams = ConfirmBookingRequest & WithBookingId

export const useConfirmBooking = (opts?: ApiMutationOpts<void, ConfirmBookingRequestWithParams>) =>
  useApiMutation({
    mutationFn: ({ id, ...data }) =>
      sendOnly({
        method: 'put',
        url: bookingConfirmUrl(id),
        requestSchema: ConfirmBookingRequest,
        data
      }),
    invalidateQueries: [queryKeys.getBookingsAll],
    ...opts
  })

export const useRestoreBooking = (opts?: ApiMutationOpts<void, BookingId>) =>
  useApiMutation({
    mutationFn: (bookingId) =>
      callOnly({
        method: 'post',
        url: bookingRestorationUrl(bookingId)
      }),
    invalidateQueries: [...affectedKeysByCreateOrDelete],
    ...opts
  })
