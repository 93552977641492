import * as z from 'zod'
import { StringToDate } from '@digital-magic/react-common/lib/utils/zod'
import { EnergyClassId, ImmovableNumber } from '@api/endpoints/settings'
import {
  ApartmentNumber,
  BuildingAddress,
  BuildingView,
  CadastreNumber,
  HouseId,
  HousePlanImages,
  HouseStatus,
  RoomsCount
} from '../buildings/houses'
import {
  FloorNumber,
  InteriorStyleType,
  LayoutTypeImages,
  LayoutTypeOptionValueId,
  LayoutTypeStyleId,
  LayoutTypeStyleImages
} from '../buildings/layoutTypes'
import {
  OptionCode,
  OptionId,
  OptionName,
  OptionValueDescription,
  OptionValueId,
  OptionValueImages,
  OptionValueName
} from '../buildings/options'
import { EmailVerificationSessionId } from '../public'
import {
  DisplayName,
  EmailAddress,
  Language,
  PhoneNumber,
  PositiveDecimal,
  PositiveInt,
  Price,
  TranslatedString
} from '../types'

export const BookingId = z.string().uuid()
export type BookingId = z.infer<typeof BookingId>

export const CustomerType = z.enum(['Person', 'Company'])
export type CustomerType = z.infer<typeof CustomerType>

export const BookingState = z.enum(['Draft', 'Submitted', 'Expired', 'Deleted'])
export type BookingState = z.infer<typeof BookingState>

export const PersonalCode = z.string().min(6).max(20)
export type PersonalCode = z.infer<typeof PersonalCode>

export const CompanyCode = z.string().min(6).max(20)
export type CompanyCode = z.infer<typeof CompanyCode>

export const VatNumber = z.string().min(11).max(14)
export type VatNumber = z.infer<typeof VatNumber>

export const BookingNumber = z.string().min(4).max(4)
export type BookingNumber = z.infer<typeof BookingNumber>

export const BookingOptionValueId = z.string().uuid()
export type BookingOptionValueId = z.infer<typeof BookingOptionValueId>

export const BookingCustomerCommon = z.object({
  customerType: CustomerType,
  email: EmailAddress,
  phone: PhoneNumber
})
export type BookingCustomerCommon = z.infer<typeof BookingCustomerCommon>

export const BookingCustomerPerson = BookingCustomerCommon.extend({
  //language: Language,
  customerType: z.literal(CustomerType.enum.Person),
  fullName: DisplayName,
  personalCode: PersonalCode
})
export type BookingCustomerPerson = z.infer<typeof BookingCustomerPerson>

export const BookingCustomerCompany = BookingCustomerCommon.extend({
  //language: Language,
  customerType: z.literal(CustomerType.enum.Company),
  companyName: DisplayName,
  companyCode: CompanyCode,
  vatNumber: VatNumber
})
export type BookingCustomerCompany = z.infer<typeof BookingCustomerCompany>

export const BookingCustomerUnion = BookingCustomerPerson.or(BookingCustomerCompany)
export type BookingCustomerUnion = z.infer<typeof BookingCustomerUnion>

export const BookingCustomer = z.object({ language: Language }).and(BookingCustomerUnion)
export type BookingCustomer = z.infer<typeof BookingCustomer>

export const BookingListItemStyle = z.object({
  layoutTypeStyleId: LayoutTypeStyleId,
  additionalPrice: Price
})
export type BookingListItemStyle = z.infer<typeof BookingListItemStyle>

export const BookingListItem = z.object({
  id: BookingId,
  bookingNumber: BookingNumber,
  status: HouseStatus,
  state: BookingState,
  address: BuildingAddress,
  apartmentNumber: ApartmentNumber.optional(),
  customerEmail: EmailAddress,
  basePrice: Price,
  totalPrice: Price,
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type BookingListItem = z.infer<typeof BookingListItem>

export const BookingsList = z.array(BookingListItem)
export type BookingsList = z.infer<typeof BookingsList>

export const BookingHouseFreeOption = z.object({
  code: OptionCode,
  id: OptionId,
  name: TranslatedString(OptionName),
  optionId: OptionId
})
export type BookingHouseFreeOption = z.infer<typeof BookingHouseFreeOption>

export const BookingOptionValue = z.object({
  //id: BookingOptionValueId,
  optionValueId: OptionValueId,
  name: TranslatedString(OptionValueName),
  description: TranslatedString(OptionValueDescription).optional(),
  images: OptionValueImages,
  price: Price,
  orderNumber: PositiveInt
})
export type BookingOptionValue = z.infer<typeof BookingOptionValue>

export const BookingOption = z.object({
  code: OptionCode,
  multiValue: z.boolean(),
  name: TranslatedString(OptionName),
  optionId: OptionId,
  orderNumber: PositiveInt,
  values: BookingOptionValue.array()
})
export type BookingOption = z.infer<typeof BookingOption>

export const BookingHouse = z.object({
  id: HouseId,
  building: BuildingView,
  cadastreNumber: CadastreNumber,
  apartmentNumber: ApartmentNumber.optional(),
  floor: FloorNumber.optional(),
  outdoorSquare: PositiveDecimal,
  indoorSquare: PositiveDecimal,
  storeRoomSquare: PositiveDecimal,
  terraceSquare: PositiveDecimal,
  roomsCount: RoomsCount,
  bedroomsCount: RoomsCount,
  hasSauna: z.boolean(),
  isInteriorIncluded: z.boolean(),
  isSanitaryIncluded: z.boolean(),
  energyClassId: EnergyClassId.optional(),
  immovableNumber: ImmovableNumber.optional(),
  planImages: HousePlanImages,
  images: LayoutTypeImages,
  freeOptions: BookingHouseFreeOption.array(),
  handoffAt: StringToDate.optional()
})
export type BookingHouse = z.infer<typeof BookingHouse>

export const BookingStyle = z.object({
  layoutTypeStyleId: LayoutTypeStyleId,
  styleType: InteriorStyleType,
  additionalPrice: Price,
  images: LayoutTypeStyleImages
})
export type BookingStyle = z.infer<typeof BookingStyle>

export const Booking = z.object({
  id: BookingId,
  bookingNumber: BookingNumber,
  status: HouseStatus,
  house: BookingHouse,
  customer: BookingCustomer,
  style: BookingStyle,
  options: BookingOption.array(),
  basePrice: Price,
  totalPrice: Price,
  state: BookingState,
  expiresAt: StringToDate,
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type Booking = z.infer<typeof Booking>

export const UpdateBookingRequest = z.object({
  verificationSessionId: EmailVerificationSessionId.optional(),
  // TODO: These fields may not be optional!!!
  customer: BookingCustomer.optional(),
  layoutTypeStyleId: LayoutTypeStyleId.optional(),
  options: LayoutTypeOptionValueId.array()
})
export type UpdateBookingRequest = z.infer<typeof UpdateBookingRequest>

export const CreateBookingRequest = UpdateBookingRequest.extend({
  houseId: HouseId
})
export type CreateBookingRequest = z.infer<typeof CreateBookingRequest>

export const ConfirmBookingRequest = z.object({
  status: HouseStatus
})
export type ConfirmBookingRequest = z.infer<typeof ConfirmBookingRequest>

export const SubmitBookingRequest = z.object({
  verificationSessionId: EmailVerificationSessionId.optional()
})
export type SubmitBookingRequest = z.infer<typeof SubmitBookingRequest>
